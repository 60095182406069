import React from "react"
import Layout from "../components/UI/Layout"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import Head from "../components/Global/Head"
import { Section, Container, M, GrayBorder } from "../components/Global"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const query = graphql`
  query($slug: String!) {
    contentfulC4FBlogPost(slug: { eq: $slug }) {
      shortDescription {
        shortDescription
      }
      picture {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      content {
        raw
      }
      keywords
      metaDescription
      title
      slug
    }
  }
`

export const OutputWrapper = styled.div`
  & h1 {
    font-size: 64px;
    color: black;
    font-weight: 500;
    margin-top: 50px;
    @media (max-width: 700px) {
      font-size: 38px;
    }
  }
  & p {
    font-size: 18px;
  }
  & h2 {
    font-size: 36px;
  }
  & a {
    color: black;
    font-size: 24px;
    transition: 0.3s;
    &:hover {
      color: darkgreen;
    }
  }
`

export const NavGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 0;
`

const BlogTemplate = (props: any) => {
  const content = props.data.contentfulC4FBlogPost.content
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        return (
          <GatsbyImage
            alt="Contentful post image"
            style={{ width: "100%", height: "600px" }}
            image={node.data.target.gatsbyImageData}
          />
        )
      },
    },
  }
  const output = renderRichText(content, options)
  return (
    <Layout
      backgroundImage={props.data.contentfulC4FBlogPost.picture.gatsbyImageData}
      isInBlog={true}
    >
      <Head
        description={props.data.contentfulC4FBlogPost.metaDescription}
        pageTitle={props.data.contentfulC4FBlogPost.title}
        keywords={props.data.contentfulC4FBlogPost.keywords}
      />
      <Section id="blog">
        <Container>
          <M>
            <OutputWrapper>
              <h1>{props.data.contentfulC4FBlogPost.title}</h1>
              <p>
                {
                  props.data.contentfulC4FBlogPost.shortDescription
                    .shortDescription
                }
              </p>
              {output}

              <br />
              <NavGrid>
                <div>
                  {props.pageContext.previous && <p>POPRZEDNI ARTYKUŁ</p>}
                </div>
                <div>{props.pageContext.next && <p>NASTĘPNY ARTYKUŁ</p>}</div>
              </NavGrid>
              <GrayBorder />
              <NavGrid>
                <div>
                  {props.pageContext.previous && (
                    <AnchorLink
                      to={"/blog/" + props.pageContext.previous.slug + "#blog"}
                    >
                      {props.pageContext.previous.title}
                    </AnchorLink>
                  )}
                </div>
                <div>
                  {props.pageContext.next && (
                    <AnchorLink
                      to={"/blog/" + props.pageContext.next.slug + "#blog"}
                    >
                      {props.pageContext.next.title}
                    </AnchorLink>
                  )}
                </div>
              </NavGrid>
              <br />
            </OutputWrapper>
          </M>
        </Container>
      </Section>
    </Layout>
  )
}

export default BlogTemplate
